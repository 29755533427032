/* make the customizations */

:root {
        --voiceme-bg: #EDF0F9;
        --voiceme-core: #5458F7;
        --voiceme-primary-accent: #5458F7;
        --voiceme-primary-black: #3B3B55;
        --voiceme-primary-white: #FFFFFF;
        --voiceme-primary-gray: #A8A8B5;
        --voiceme-border-hover: #5458F7;
        --voiceme-grey-tertiary: #EDF0F9;
        --voiceme-grey-quatinary: #FEFEFF;
        --voiceme-grey-secondary: #FAFBFC;
        --voiceme-yellow-feedback: #FFCD00;
}

$theme-colors: (
  "primary":    #5458F7,
  "secondary":   #A8A8B5,
  "success":    #EDF0F9,
  "info":       #FAFBFC,
  "warning":    #FFCD00,
  "danger":     #FFCD00,
  "light":      #FFFFFF,
  "dark":       #3B3B55
);

/* import bootstrap to set changes */
@import "../node_modules/bootstrap/scss/bootstrap";
